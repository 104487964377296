<!--<div class="sawasdee20x">-->
<!--    <div  [ngStyle]="{'background':'url('+incomingFormSettings.getBanner()+') center / cover','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}"-->
<!--          class="containerRelative">-->
<!--        <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'contain'}"-->
<!--             src="{{incomingFormSettings.getBanner()}}" #img />-->
<!--        <div class="centered">-->
<!--            <div class="col-md-12 col-sm-12 col-xs-12 form-group  top_search">-->
<!--                <div [ngStyle]="{'border-radius': '25px','background':'#fff'}" class="mdl-shadow&#45;&#45;2dp">-->
<!--                    <div>-->
<!--                        <table [ngStyle]="{'min-width':'350px','margin':'5px'}">-->
<!--                            <td>-->
<!--                                <input  [ngStyle]="{'color':'#000','text-align':'center'}"-->
<!--                                        autocomplete="off"-->
<!--                                        #elmScanner-->
<!--                                        ngClass="searchbar"-->
<!--                                        placeholder=""-->
<!--                                        (change)="onScanning($event,elmScanner)">-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <mat-icon matSuffix [ngStyle]="{'color':this.incomingFormSettings.getBackgroundColor()}">qr_code</mat-icon>-->
<!--                            </td>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->
<!--&lt;!&ndash;                <div class="input-group">&ndash;&gt;-->
<!--&lt;!&ndash;                    <input type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                           class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="scan..."&ndash;&gt;-->
<!--&lt;!&ndash;                           autocomplete="off"&ndash;&gt;-->
<!--&lt;!&ndash;                           #elmScanner&ndash;&gt;-->
<!--&lt;!&ndash;                           (change)="onScanning($event,elmScanner)"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="input-group-btn">&ndash;&gt;-->
<!--&lt;!&ndash;                                <button class="btn btn-default"&ndash;&gt;-->
<!--&lt;!&ndash;                                        type ="button">&ndash;&gt;-->
<!--&lt;!&ndash;                                Go&ndash;&gt;-->
<!--&lt;!&ndash;                                </button>&ndash;&gt;-->
<!--&lt;!&ndash;                            </span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;                    <mat-form-field [ngStyle]="{'width':'350px'}"&ndash;&gt;-->
<!--            &lt;!&ndash;                                    appearance="fill">&ndash;&gt;-->
<!--            &lt;!&ndash;                        <mat-label></mat-label>&ndash;&gt;-->
<!--            &lt;!&ndash;                        <input  matInput&ndash;&gt;-->
<!--            &lt;!&ndash;                                autocomplete="off"&ndash;&gt;-->
<!--            &lt;!&ndash;                                #elmScanner&ndash;&gt;-->
<!--            &lt;!&ndash;                                placeholder=""&ndash;&gt;-->
<!--            &lt;!&ndash;                                (change)="onScanning($event,elmScanner)">&ndash;&gt;-->
<!--            &lt;!&ndash;                        <mat-icon matSuffix>qr_code_2</mat-icon>&ndash;&gt;-->
<!--            &lt;!&ndash;                    </mat-form-field>&ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->
<!--&lt;!&ndash;    <mat-tab-group>&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-tab label="scanner">&ndash;&gt;-->
<!--&lt;!&ndash;            &ndash;&gt;-->
<!--&lt;!&ndash;        </mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-tab label="webcam">&ndash;&gt;-->
<!--&lt;!&ndash;            <zxing-scanner&ndash;&gt;-->
<!--&lt;!&ndash;                    #scanner&ndash;&gt;-->
<!--&lt;!&ndash;                    [formats]="this.formats"&ndash;&gt;-->
<!--&lt;!&ndash;                    (scanSuccess)="scanSuccessHandler($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                    [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}">&ndash;&gt;-->
<!--&lt;!&ndash;            </zxing-scanner>&ndash;&gt;-->
<!--&lt;!&ndash;        </mat-tab>&ndash;&gt;-->
<!--&lt;!&ndash;    </mat-tab-group>&ndash;&gt;-->
<!--</div>-->


<zxing-scanner
        #scanner
        [formats]="this.formats"
        (scanSuccess)="scanSuccessHandler($event)"
        [ngStyle]="{'width':service.getWidth()+'px','height':service.getHeight()+'px'}">
</zxing-scanner>
